import React from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import Typography from "@material-ui/core/Typography";
import ConditionalWrapper from "@micado-digital/react-ui/components/ConditionalWrapper";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";
import ReactLink from "@micado-digital/react-link/ReactLink";
import FallbackImage from "@micado-digital/react-ui/components/FallbackImage";
import Progress from "../../Progress";

import styles from "./Item.styles";

const Content = ({ lang, mediaList, progress, title }) => {
	const css = styles();

	return (
		<>
			<div
				className={clsx(
					css.image,
					"mco-shop-myaccount-digital-products-list-item__image-wrapper"
				)}
			>
				{!isEmpty(mediaList) ? (
					<ReactHTMLPicture
						media={mediaList?.[0]}
						mediaFormats={{
							xs: "product-teaser"
						}}
					/>
				) : (
					<div className={css.fallbackImage}>
						<FallbackImage />
					</div>
				)}
			</div>
			<div
				className={clsx(css.content, "mco-shop-myaccount-digital-products-list-item__content")}
			>
				<Typography
					className={clsx(css.title, "mco-shop-myaccount-digital-products-list-item__title")}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				<Progress lang={lang} value={progress} />
			</div>
		</>
	);
};

const Item = ({ lang, product }) => {
	const { id, mediaList, progress, title, valid = true } = product;
	const css = styles();

	return (
		<>
			<ConditionalWrapper
				condition={valid}
				wrapper={children => (
					<ReactLink
						className={clsx(css.item, "mco-shop-myaccount-digital-products-list-item", {
							[css.disabled]: !valid
						})}
						to={`/${lang}/shop/myaccount/products/${id}`}
					>
						{children}
					</ReactLink>
				)}
			>
				<ConditionalWrapper
					condition={!valid}
					wrapper={children => (
						<div
							className={clsx(css.item, "mco-shop-myaccount-digital-products-list-item", {
								[css.disabled]: !valid
							})}
						>
							{children}
						</div>
					)}
				>
					<Content lang={lang} mediaList={mediaList} progress={progress} title={title} />
				</ConditionalWrapper>
			</ConditionalWrapper>
		</>
	);
};

export default Item;
