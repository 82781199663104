import React from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";
import FallbackImage from "@micado-digital/react-ui/components/FallbackImage";

import styles from "./Image.styles";

const Image = ({ media }) => {
	const css = styles();

	return (
		<div className={clsx(css.image, "mco-shop-myaccount-digital-product-details__image")}>
			{!isEmpty(media) ? (
				<ReactHTMLPicture
					media={media}
					mediaFormats={{
						xs: "product-detail"
					}}
				/>
			) : (
				<FallbackImage />
			)}
		</div>
	);
};

export default Image;
