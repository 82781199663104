import React, { useCallback, useEffect, useState } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";
import updateProgress from "../../../../utils/updateProgress";

import styles from "./VideoPlayer.styles";

const ReactPlayer = loadable(() => import("react-player"));

const VideoPlayer = ({
	data,
	isPlaying,
	playerRef,
	setData,
	setIsPlaying,
	setReload,
	setVideo,
	video = {}
}) => {
	const { id: itemID, media = {}, productID: id, progress = 0 } = video || {};
	const { filename } = media;
	const [isReady, setIsReady] = useState(false);
	const css = styles();

	useEffect(() => {
		setIsReady(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(video)]);

	const handleEnded = async () => {
		await handleProgress(1);

		setReload(new Date().getTime());
		setVideo({});
	};

	const handleProgress = async (progress = 0) => {
		if (!progress) return;

		const percentage = Math.round(progress * 100);
		const _progress = percentage >= 98 ? 100 : percentage;

		await updateProgress({ id, itemID, progress: _progress });

		let tmpData = [...data];

		tmpData = tmpData?.map(item => {
			if (item?.id === video?.id) return { ...item, progress: _progress };

			return item;
		});

		setData(tmpData);
		setVideo(prevVideo => ({ ...prevVideo, progress: _progress }));
	};

	const handleReady = useCallback(() => {
		if (!isReady) {
			playerRef.current.seekTo(progress / 100);
			setIsReady(true);
		}
	}, [isReady, playerRef, progress]);

	if (!filename) return <></>;

	return (
		<div
			className={clsx(
				css.videoPlayer,
				"mco-shop-myaccount-digital-product-details-video-player"
			)}
		>
			<ReactPlayer
				className={clsx(
					css.player,
					"mco-shop-myaccount-digital-product-details-video-player__player"
				)}
				controls={true}
				height="100%"
				key={itemID}
				playing={isPlaying}
				ref={playerRef}
				url={filename}
				width="100%"
				onEnded={handleEnded}
				onPause={() => setIsPlaying(false)}
				onPlay={() => setIsPlaying(true)}
				onProgress={({ played = 0 }) => {
					handleProgress(played);
				}}
				onReady={() => handleReady()}
			/>
		</div>
	);
};

export default VideoPlayer;
