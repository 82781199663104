import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	content: {
		"& .mco-fallback-image": {
			marginBottom: theme.spacing(4),
			paddingTop: "56.25%",
			position: "relative"
		},
		"& .mco-shop-accordion": {
			marginTop: 1
		},
		"& .mco-shop-myaccount-digital-product-details-video": {
			marginTop: 1
		}
	}
}));

export default styles;
