import React, { useMemo } from "react";
import clsx from "clsx";
import UIButton from "@material-ui/core/Button";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@micado-digital/react-ui/components/Local";
import localDE from "../../../../locals/de.json";
import localEN from "../../../../locals/en.json";

import styles from "./Button.styles";

const Button = ({ isPlaying, lang, progress = 0, onClick }) => {
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const label = useMemo(() => {
		if (isPlaying) {
			return <Local identifier="reactShop.myAccount.digitalProducts.buttons.stop" l={l} />;
		}

		if (progress === 100) {
			return (
				<Local identifier="reactShop.myAccount.digitalProducts.buttons.startAgain" l={l} />
			);
		}

		if (progress > 0) {
			return <Local identifier="reactShop.myAccount.digitalProducts.buttons.continue" l={l} />;
		}

		return <Local identifier="reactShop.myAccount.digitalProducts.buttons.start" l={l} />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [l, progress]);

	return (
		<div className={clsx(css.button, "mco-shop-myaccount-digital-product-details-button")}>
			<UIButton color="secondary" fullWidth variant="contained" onClick={onClick}>
				{label}
			</UIButton>
		</div>
	);
};

export default Button;
