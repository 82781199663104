import axios from "axios";

const updateProgress = async ({ id, itemID, progress = 0 }) => {
	if (!id || !itemID) return;

	const { REACT_APP_PATH } = process.env;
	const FETCH_URL = `${REACT_APP_PATH}/Micado.Shop.Web/Micado.Shop.Web.IO.Api.DigitalProductApi/UpdateProgress.api`;

	const formData = new FormData();

	formData.append("id", id);
	formData.append("itemID", itemID);
	formData.append("progress", progress);

	const headers = {};

	if (localStorage.getItem("logintoken")) {
		headers["Authorization"] = "Bearer " + localStorage.getItem("logintoken");
	}

	try {
		const response = await axios.post(FETCH_URL, formData, {
			headers
		});

		const data = await response.data;

		return data;
	} catch (error) {
		return error;
	}
};

export default updateProgress;
