import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import loadable from "@loadable/component";
import Container from "@material-ui/core/Container";
import PageContext from "@micado-digital/react-ui/context/PageContext";

import styles from "./DigitalProductDetail01.styles";

const DigitalProductAccessCode = loadable(() =>
	import("@micado-digital/react-shop/components/DigitalProductAccessCode")
);
const DigitalProductDetails = loadable(() => import("../MyAccount/DigitalProductDetails"));

const DigitalProductDetail01 = ({ defaultLang = "de" }) => {
	const [pageData] = useContext(PageContext);
	const { lang: pageDataLang } = pageData || {};
	const [accessCode, setAcccessCode] = useState(null);
	const [lang, setLang] = useState(defaultLang);
	const { search } = useLocation();
	const css = styles();

	const params = new URLSearchParams(search);
	const token = params.get("token");

	useEffect(() => {
		if (!pageDataLang) return;

		setLang(pageDataLang);
	}, [pageDataLang]);

	const handleSubmit = data => {
		const { accessCode, data: { valid } = {} } = data;

		if (valid) {
			setAcccessCode(accessCode);
		}
	};

	return (
		<>
			{accessCode ? (
				<Container
					className={clsx(css.digitalProductDetail, "mco-shop-digital-product-detail")}
					maxWidth="lg"
				>
					<DigitalProductDetails accessCode={accessCode} lang={lang} token={token} />
				</Container>
			) : (
				<Container
					className={clsx(css.digitalProductDetail, "mco-shop-digital-product-detail")}
					maxWidth="sm"
				>
					<DigitalProductAccessCode lang={lang} token={token} onSubmit={handleSubmit} />
				</Container>
			)}
		</>
	);
};

export default DigitalProductDetail01;
