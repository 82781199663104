import React, { useMemo } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@micado-digital/react-ui/components/Local";
import localDE from "../../../../locals/de.json";
import localEN from "../../../../locals/en.json";
import getVideo from "../../../../utils/getVideo";

import styles from "./Video.styles";

const Video = ({
	id,
	isPlaying,
	lang,
	media,
	productID,
	progress,
	setIsPlaying,
	setVideo,
	video
}) => {
	const { id: currentVideoID } = video || {};
	const { title } = media || {};
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const buttonLabel = useMemo(() => {
		if (isPlaying && id === currentVideoID) {
			return <Local identifier="reactShop.myAccount.digitalProducts.buttons.stop" l={l} />;
		}

		if (progress === 0) {
			return <Local identifier="reactShop.myAccount.digitalProducts.buttons.play" l={l} />;
		}

		if (progress === 100) {
			return (
				<Local identifier="reactShop.myAccount.digitalProducts.buttons.playAgain" l={l} />
			);
		}

		return <Local identifier="reactShop.myAccount.digitalProducts.buttons.continue" l={l} />;
	}, [currentVideoID, id, isPlaying, l, progress]);

	const handleClick = async () => {
		if (id === currentVideoID) {
			if (isPlaying) {
				setIsPlaying(false);
				return;
			}

			setIsPlaying(true);
			return;
		}

		const { url } = await getVideo(media?.filename);

		if (url) {
			setIsPlaying(true);
			setVideo({
				id,
				productID,
				media: { ...media, filename: url },
				progress
			});
		}
	};

	if (isEmpty(media)) return <></>;

	return (
		<div className={clsx(css.video, "mco-shop-myaccount-digital-product-details-video")}>
			<div
				className={clsx(
					css.wrapper,
					"mco-shop-myaccount-digital-product-details-video__wrapper"
				)}
			>
				{id === currentVideoID ? (
					<ReactSVGIcon
						className={clsx(
							css.icon,
							"mco-shop-myaccount-digital-product-details-video__icon"
						)}
						color="initial"
						src="/img/icons/circle-half.svg"
						size={40}
					/>
				) : (
					<>
						{progress === 100 ? (
							<ReactSVGIcon
								className={clsx(
									css.icon,
									"mco-shop-myaccount-digital-product-details-video__icon"
								)}
								color="initial"
								src="/img/icons/circle-check.svg"
								size={40}
							/>
						) : (
							<ReactSVGIcon
								className={clsx(
									css.icon,
									"mco-shop-myaccount-digital-product-details-video__icon"
								)}
								color="initial"
								src="/img/icons/circle-empty.svg"
								size={40}
							/>
						)}
					</>
				)}

				<Typography
					className={clsx(
						css.title,
						"mco-shop-myaccount-digital-product-details-video__title",
						{ [css.titleDisabled]: progress < 100 }
					)}
				>
					{title}
				</Typography>
			</div>
			<Button color="secondary" onClick={handleClick}>
				{buttonLabel}
			</Button>
		</div>
	);
};

export default Video;
