import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	video: {
		alignItems: "center",
		background: "#FFFFFF",
		display: "flex",
		justifyContent: "space-between",
		minHeight: 74,
		padding: theme.spacing(0, 3),
		[theme.breakpoints.up("sm")]: {
			paddingLeft: 40,
			paddingRight: 40
		}
	},
	wrapper: {
		alignItems: "center",
		display: "flex"
	},
	icon: {
		marginRight: theme.spacing(2)
	},
	title: {
		fontSize: "1.25rem",
		fontWeight: theme.typography.fontWeightBold,
		lineHeight: 1.3
	},
	titleDisabled: {
		color: "#8AA098"
	}
}));

export default styles;
