import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import customTheme from "./themes/project.json";
import Locals from "@micado-digital/react-ui/components/Locals";
import Ui from "@micado-digital/react-ui/components/Ui";
import UserData from "@micado-digital/react-ui/components/UserData";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import BookmarkRoot from "@micado-digital/react-bookmark/ReactBookmark/bookmarkRoot";
import Bookmark from "@micado-digital/react-shop/views/Bookmark01";
import BookmarkIcon from "@micado-digital/react-shop/components/BookmarkIcon";
import CartIcon from "@micado-digital/react-shop/components/CartIcon";
import CartOverlay from "@micado-digital/react-shop/Cart/CartOverlay";
import MyAccount from "@micado-digital/react-shop/views/MyAccount01";
import ShopData from "@micado-digital/react-shop/components/Shop";
import Shop from "@micado-digital/react-shop/views/Shop01";
import UserIcon from "@micado-digital/react-shop/components/UserIcon";
import DigitalProductDetail from "./views/DigitalProductDetail";
import DigitalProductDetails from "./views/MyAccount/DigitalProductDetails";
import Item from "./views/MyAccount/DigitalProducts/Item";
import Menu from "./views/MyAccount/Menu";
import ProductDetail from "./views/ProductDetail";
import ProductOverview from "./views/ProductOverview";
import ProductTeaser from "./components/ProductTeaser";

import "./css/index.css";

const lang = document.getElementsByTagName("html")[0].getAttribute("lang");
const theme = createTheme(customTheme);

const Core = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui>
						<WebPageData>
							<WebProfile>
								<UserData>
									<ShopData lang={lang}>
										<BookmarkRoot>
											<Locals url={`/locals/${lang}.json`}>
												{children}
												<CartOverlay lang={lang} />
											</Locals>
										</BookmarkRoot>
									</ShopData>
								</UserData>
							</WebProfile>
						</WebPageData>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

if (process.env.NODE_ENV === "development") {
	ReactDOM.render(
		<Core>
			<Routes>
				<Route
					path="/:lang/shop/bookmark"
					element={<Bookmark defaultLang={lang} productTeaserComponent={<ProductTeaser />} />}
				/>
				<Route
					path="/:lang/shop/digital-product"
					element={<DigitalProductDetail defaultLang={lang} />}
				/>
				<Route
					path="/:lang/shop/myaccount/*"
					element={
						<MyAccount
							menuComponent={<Menu lang={lang} />}
							productTeaserComponent={<Item />}
							routeComponents={{ productDetails: <DigitalProductDetails lang={lang} /> }}
						/>
					}
				/>
				<Route path="/:lang/shop/produkt/*" element={<ProductDetail defaultLang={lang} />} />
				<Route
					path="/:lang/shop/*"
					element={
						<Shop defaultComponent={<ProductOverview defaultLang={lang} />} lang="de" />
					}
				/>
			</Routes>
		</Core>,
		document.getElementById("root")
	);
}

if (process.env.NODE_ENV === "production") {
	const icons = document.querySelectorAll("#mco-shop-icons");

	if (icons) {
		icons.forEach(element => {
			ReactDOM.render(
				<Core>
					<div className="mco-shop-icons">
						<UserIcon />
						<BookmarkIcon />
						<CartIcon />
					</div>
				</Core>,
				element
			);
		});
	}

	const shop = document.getElementById("mco-shop");

	if (shop) {
		ReactDOM.render(
			<Core>
				<Routes>
					<Route
						path="/:lang/shop/bookmark"
						element={
							<Bookmark defaultLang={lang} productTeaserComponent={<ProductTeaser />} />
						}
					/>
					<Route
						path="/:lang/shop/digital-product"
						element={<DigitalProductDetail defaultLang={lang} />}
					/>
					<Route
						path="/:lang/shop/myaccount/*"
						element={
							<MyAccount
								menuComponent={<Menu lang={lang} />}
								productTeaserComponent={<Item />}
								routeComponents={{ productDetails: <DigitalProductDetails lang={lang} /> }}
							/>
						}
					/>
					<Route path="/:lang/shop/produkt/*" element={<ProductDetail defaultLang={lang} />} />
					<Route
						path="/:lang/shop/*"
						element={
							<Shop defaultComponent={<ProductOverview defaultLang={lang} />} lang="de" />
						}
					/>
				</Routes>
			</Core>,
			shop
		);
	}
}
