import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	videoPlayer: {
		marginBottom: theme.spacing(4),
		paddingTop: "56.25%",
		position: "relative"
	},
	player: {
		left: 0,
		position: "absolute",
		top: 0
	}
}));

export default styles;
