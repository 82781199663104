import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	progress: {
		alignItems: "flex-end",
		display: "flex"
	},
	line: {
		background: "#DFDFDF",
		flex: 1,
		height: 8,
		marginBottom: 5,
		"& > div": {
			background: theme.palette?.secondary?.main
		}
	},
	wrapper: {
		alignItems: "flex-end",
		display: "flex",
		flexDirection: "column",
		marginLeft: theme.spacing(2)
	},
	value: {
		color: theme.palette?.secondary?.main,
		fontSize: 28,
		lineHeight: 1
	},
	label: {
		color: theme.palette?.text?.primary,
		lineHeight: 1
	}
}));

export default styles;
