import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(() => ({
	container: {
		maxWidth: 1340,
		paddingLeft: 20,
		paddingRight: 20
	}
}));

export default styles;
