import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	title: {
		fontSize: "1.25rem",
		fontWeight: theme.typography.fontWeightBold,
		lineHeight: 1.3,
		marginBottom: theme.spacing(2)
	},
	tabs: {
		marginBottom: theme.spacing(4)
	},
	item: {
		marginBottom: theme.spacing(1),
		"&:last-child": {
			marginBottom: 0
		}
	},
	link: {
		color: theme.palette?.text?.secondary,
		cursor: "pointer",
		display: "block",
		transition: theme.transitions.create("color", {
			duration: theme.transitions.duration.shorter
		}),
		"& span": {
			fontSize: "1.25rem",
			fontWeight: theme.typography.fontWeightMedium
		},
		"&:hover": {
			color: theme.palette?.text?.primary
		}
	},
	linkActive: {
		color: `${theme.palette?.primary?.main} !important`,
		"& span": {
			fontWeight: theme.typography.fontWeightBold
		}
	}
}));

export default styles;
