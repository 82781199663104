import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	title: {
		marginBottom: theme.spacing(4),
		"& h1": {
			color: "#000000",
			fontSize: "clamp(35px, 6vw, 44px)"
		},
		[theme.breakpoints.up("md")]: {
			marginBottom: theme.spacing(7.5)
		}
	},
	rightColumn: {
		background: "#FFFFFF",
		marginBottom: theme.spacing(4),
		padding: theme.spacing(2),
		[theme.breakpoints.up("md")]: {
			background: "#FFFFFF",
			float: "right",
			marginBottom: 0,
			padding: theme.spacing(4),
			position: "sticky",
			top: 0,
			width: 410
		}
	},
	content: {
		[theme.breakpoints.up("md")]: {
			marginRight: 445
		}
	},
	price: {
		"& .mco-shop-price__value": {
			color: "#AFA08B",
			fontSize: "clamp(28px, 4vw, 48px)",
			lineHeight: 1.1
		}
	},
	combinationSelection: {
		marginTop: theme.spacing(2)
	},
	gallery: {
		marginBottom: theme.spacing(2)
	},
	description: {
		marginTop: theme.spacing(4),
		"& .mco-shop-product-detail-description__text": {
			fontWeight: theme.typography.fontWeightLight
		}
	},
	buttons: {
		display: "flex",
		marginTop: theme.spacing(4),
		"& .mco-shop-product-detail-quantity": {
			marginRight: theme.spacing(2)
		},
		"& .mco-shop-product-detail-cart-button": {
			flex: 1
		}
	}
}));

export default styles;
