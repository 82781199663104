import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import loadable from "@loadable/component";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import Typography from "@material-ui/core/Typography";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@micado-digital/react-ui/components/Local";
import localDE from "@micado-digital/react-shop/locals/de.json";
import localEN from "@micado-digital/react-shop/locals/en.json";
import localIT from "@micado-digital/react-shop/locals/it.json";
import useDigitalProductDetails from "@micado-digital/react-shop/hooks/useDigitalProductDetails";
import Button from "./Button";
import Content from "./Content";
import Image from "./Image";
import OrderDate from "./OrderDate";
import Progress from "../Progress";
import Valid from "./Valid";
import VideoPlayer from "./VideoPlayer";
import getVideo from "../../../utils/getVideo";

import styles from "./DigitalProductDetails.styles";

const Alert = loadable(() => import("@material-ui/lab/Alert"));
const Preloader = loadable(() => import("@micado-digital/react-shop/components/Preloader"));

const DigitalProductDetails = ({ accessCode, lang, token }) => {
	const { productID } = useParams();
	const [reload, setReload] = useState(null);
	const details = useDigitalProductDetails({ accessCode, id: productID, lang, reload, token });
	let {
		items = [],
		mediaList,
		orderDate,
		progress = 0,
		title,
		validTo,
		_loading = false
	} = details;
	const [data, setData] = useState([]);
	const [isPlaying, setIsPlaying] = useState(false);
	const [video, setVideo] = useState({});
	const { id: currentVideoID } = video || {};
	const playerRef = useRef();
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN,
			it: localIT
		}
	});

	useEffect(() => {
		setData(items);
	}, [items]);

	const entries = useMemo(() => {
		return data.length;
	}, [data]);

	const hasVideos = useMemo(() => {
		return data.some(({ media: { mediaType } = {} }) => mediaType === "video");
	}, [data]);

	const handleClick = useCallback(async () => {
		let item = {};

		if (progress === 100) {
			item = data?.find(({ media: { mediaType } = {} }) => mediaType === "video");
		} else {
			item = data?.find(
				({ media: { mediaType } = {}, progress }) => mediaType === "video" && progress < 100
			);
		}

		if (isEmpty(item)) return;

		if (item?.id === currentVideoID) {
			if (isPlaying) {
				setIsPlaying(false);
				return;
			}

			setIsPlaying(true);
			return;
		}

		const { url } = await getVideo(item?.media?.filename);

		if (url) {
			setIsPlaying(!isPlaying);
			setVideo({
				...item,
				productID: parseInt(productID),
				media: { ...item?.media, filename: url }
			});
		}
	}, [currentVideoID, data, isPlaying, productID, progress]);

	return (
		<div className={clsx(css.details, "mco-shop-myaccount-digital-product-details")}>
			{_loading ? (
				<Preloader />
			) : (
				<>
					<Typography
						className={clsx(css.title, "mco-shop-myaccount-digital-product-details__title")}
						dangerouslySetInnerHTML={{ __html: title }}
						variant="h1"
					/>
					<div
						className={clsx(
							css.wrapper,
							"mco-shop-myaccount-digital-product-details__wrapper"
						)}
					>
						{!!entries && (
							<>
								<div className={css.rightColumn}>
									{hasVideos ? (
										<>
											<Progress lang={lang} value={progress} />
											<Button
												isPlaying={isPlaying}
												lang={lang}
												progress={progress}
												onClick={handleClick}
											/>
										</>
									) : (
										<>
											{orderDate && <OrderDate date={orderDate} lang={lang} />}
											{validTo && <Valid lang={lang} to={validTo} />}
										</>
									)}
								</div>
								<div className={css.content}>
									{hasVideos && !isEmpty(video) ? (
										<VideoPlayer
											data={data}
											isPlaying={isPlaying}
											playerRef={playerRef}
											setData={setData}
											setIsPlaying={setIsPlaying}
											setReload={setReload}
											setVideo={setVideo}
											video={video}
										/>
									) : (
										<Image media={mediaList?.[0]} />
									)}
									<Content
										isPlaying={isPlaying}
										items={data}
										lang={lang}
										productID={parseInt(productID)}
										setIsPlaying={setIsPlaying}
										setVideo={setVideo}
										video={video}
									/>
								</div>
							</>
						)}
						{!entries && !_loading && (
							<Alert
								severity="info"
								className={clsx(
									css.noResult,
									"mco-shop-myaccount-digital-product-details__no-result"
								)}
							>
								<Local
									identifier="reactShop.messages.errors.DIGITAL_PRODUCTS_NO_ITEMS"
									l={l}
								/>
							</Alert>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default DigitalProductDetails;
