import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useLink from "@micado-digital/react-link/hooks/useLink";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "@micado-digital/react-shop/locals/de.json";
import localEN from "@micado-digital/react-shop/locals/en.json";
import localIT from "@micado-digital/react-shop/locals/it.json";

import styles from "./Menu.styles";

const Menu = ({ lang }) => {
	const css = styles();
	const [localsLoaded, setLocalsLoaded] = useState(false);
	const [value, setValue] = useState(0);
	const location = useLocation();
	const navigate = useLink();
	const { pathname } = location || {};
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN,
			it: localIT
		}
	});

	useEffect(() => {
		if (!isEmpty(l("reactShop"))) {
			setLocalsLoaded(true);
		}
	}, [l]);

	const menuGroup = useMemo(() => {
		return [
			{
				identifier: "home",
				title: l("reactShop.myAccount.menu.title"),
				link: `/${lang}/shop/myaccount`
			},
			{
				identifier: "products",
				title: l("reactShop.myAccount.menu.labels.digitalProducts"),
				link: `/${lang}/shop/myaccount/products`
			},
			{
				identifier: "orders",
				title: l("reactShop.myAccount.menu.labels.orders"),
				link: `/${lang}/shop/myaccount/orders`
			},
			{
				identifier: "customer",
				title: l("reactShop.myAccount.menu.labels.customer"),
				link: `/${lang}/shop/myaccount/customer`
			},
			{
				identifier: "addresses",
				title: l("reactShop.myAccount.menu.labels.addresses"),
				link: `/${lang}/shop/myaccount/addresses`
			},
			{
				identifier: "logout",
				title: l("reactShop.myAccount.menu.labels.logout"),
				link: `/${lang}/shop/logout`
			}
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, localsLoaded]);

	useEffect(() => {
		const index = menuGroup?.findIndex(({ link }) => pathname === link);

		if (index === -1) return;

		setValue(index ?? 0);
	}, [pathname, menuGroup]);

	const handleChange = (event, newValue) => {
		const link = menuGroup?.[newValue]?.link;

		if (!link) return;

		navigate(link);

		setValue(newValue);
	};

	return (
		<div className={clsx(css.menu, "mco-shop-myaccount-menu")}>
			<Tabs
				classes={{ root: clsx(css.tabs, "mco-shop-myaccount-menu__tabs") }}
				indicatorColor="primary"
				scrollButtons="auto"
				textColor="primary"
				value={value}
				variant="scrollable"
				onChange={handleChange}
			>
				{menuGroup?.map(({ identifier, link, title }, index) => {
					const isActive = pathname.indexOf(link) > -1 && link !== `/${lang}/shop/myaccount`;

					return (
						<Tab
							classes={{
								root: clsx(css.tab, "mco-shop-myaccount-menu__tab", {
									[css.tabActive]: isActive,
									"mco-shop-myaccount-menu__tab--active": isActive
								})
							}}
							data-identifier={identifier}
							key={index}
							label={title}
						/>
					);
				})}
			</Tabs>
		</div>
	);
};

export default Menu;
