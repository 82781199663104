import React, { useContext, useEffect, useRef, useState } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import PageContext from "@micado-digital/react-ui/context/PageContext";
import Filter from "@micado-digital/react-filter/Filter";
import ConditionalWrapper from "@micado-digital/react-ui/components/ConditionalWrapper";
import useFilter from "@micado-digital/react-ui/hooks/useFilter";
import useFetch from "@micado-digital/react-ui/hooks/useFetch";
import List from "@micado-digital/react-shop/views/ProductOverview01/List";
import parseStringToArray from "@micado-digital/react-shop/utils/parseStringToArray";
import Container from "../../components/Container";
import ProductTeaser from "../../components/ProductTeaser";

import styles from "./ProductOverview.styles";

const Buttons = loadable(() => import("./Buttons"));
const FilterButton = loadable(() =>
	import("@micado-digital/react-shop/views/ProductOverview01/Filter/FilterButton")
);
const FilterConnector = loadable(() =>
	import("@micado-digital/react-shop/views/ProductOverview01/Filter/FilterConnector")
);
const Preloader = loadable(() => import("@micado-digital/react-shop/components/Preloader"));
const SelectedFilter = loadable(() =>
	import("@micado-digital/react-shop/views/ProductOverview01/SelectedFilter")
);

const ProductOverview = ({
	defaultLang = "de",
	excludedFilter = ["groups"],
	page = 1,
	pageSize = 16,
	selectedFilter = [],
	selectedGroup = "",
	sort = 0
}) => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { lang: pageDataLang } = pageData || {};
	const [filterOpen, setFilterOpen] = useState(false);
	const [localValues, setLocalValues] = useState({});
	const [lang, setLang] = useState(defaultLang);
	const refForm = useRef(null);
	const refList = useRef(null);
	const filter = useFilter(
		refForm,
		{
			groups: selectedGroup ?? "",
			lang,
			page,
			pageSize,
			sort
		},
		[{ page: 1 }]
	);
	const _filterListURL = `${REACT_APP_PATH}/Micado.Shop.Web/Micado.Shop.Web.IO.Api.ProductApi/FilterList.api`;
	const _listURL = `${REACT_APP_PATH}/Micado.Shop.Web/Micado.Shop.Web.IO.Api.ProductApi/List.api`;
	const products = useFetch(
		{
			data: { ...filter, action: "filter" },
			url: _listURL,
			method: "GET"
		},
		[filter],
		!isEmpty(filter)
	);
	const { entries = 0, items = [], _loading } = products || {};
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const css = styles();

	useEffect(() => {
		if (!pageDataLang) return;

		setLang(pageDataLang);
	}, [pageDataLang]);

	useEffect(() => {
		setFilterOpen(false);

		let _localValues = {};

		for (const key in filter) {
			_localValues[key] = parseStringToArray(filter[key]) ?? [];
		}

		_localValues.groups = filter.groups ?? selectedGroup;
		_localValues.lang = filter.lang ?? lang;
		_localValues.page = filter.page ? parseInt(filter.page) : page;
		_localValues.pageSize = filter.pageSize ? parseInt(filter.pageSize) : pageSize;
		_localValues.price = filter?.price ?? "";
		_localValues.sort = filter.sort ? parseInt(filter.sort) : sort;

		setLocalValues(_localValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const handleClearFilter = () => {
		setLocalValues({
			groups: selectedGroup ?? "",
			lang,
			page,
			pageSize,
			price: "",
			sort
		});

		handleSubmit(100);
	};

	const handlePaginate = (e, page) => {
		setLocalValues({ ...localValues, page });

		handleSubmit(100);
	};

	const handleSubmit = (delay = 10) => {
		setTimeout(() => {
			submitForm();
		}, delay);
	};

	const submitForm = () => {
		const evt = new CustomEvent("submit", { bubbles: true, cancelable: true });
		refForm.current.dispatchEvent(evt);
	};

	return (
		<Container
			className={clsx(css.productOverview, "mco-shop-product-overview")}
			maxWidth="lg"
		>
			<div className={clsx(css.wrapper, "mco-shop-product-overview__wrapper")}>
				{isMobile && <Buttons lang={lang} setFilterOpen={setFilterOpen} />}
			</div>
			<Filter
				api={_filterListURL}
				excludedFilter={excludedFilter}
				lang={lang}
				localValues={localValues}
				preSelectedValues={{}}
				refForm={refForm}
				selectedFilter={selectedFilter}
				setLocalValues={setLocalValues}
			>
				<ConditionalWrapper
					condition={isMobile}
					wrapper={children => (
						<Drawer
							anchor="left"
							classes={{
								paper: css.paper
							}}
							open={filterOpen}
							variant="persistent"
							onClose={setFilterOpen}
						>
							{children}
						</Drawer>
					)}
				>
					<div className={clsx(css.filter, "mco-shop-product-overview__filter")}>
						<div className={css.filterWrapper}>
							<FilterConnector
								lang={lang}
								maxVisibleOptions={6}
								setFilterOpen={setFilterOpen}
								onSubmit={handleSubmit}
							/>
						</div>
						{isMobile && <FilterButton lang={lang} />}
					</div>
				</ConditionalWrapper>
				<SelectedFilter
					lang={lang}
					onClearFilter={handleClearFilter}
					onSubmit={handleSubmit}
				/>
			</Filter>
			{_loading ? (
				<Preloader />
			) : (
				<List
					currentPage={localValues.page}
					items={items}
					itemsCounter={entries}
					itemsPerPage={pageSize}
					lang={lang}
					loading={_loading}
					productTeaserComponent={<ProductTeaser />}
					ref={refList}
					onPaginate={handlePaginate}
				/>
			)}
		</Container>
	);
};

export default ProductOverview;
