import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	wrapper: {
		marginTop: theme.spacing(4)
	},
	rightColumn: {
		background: "#FFFFFF",
		marginBottom: theme.spacing(4),
		padding: theme.spacing(4, 2),
		[theme.breakpoints.up("md")]: {
			background: "#FFFFFF",
			float: "right",
			marginBottom: 0,
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
			position: "sticky",
			top: 0,
			width: 410
		}
	},
	content: {
		[theme.breakpoints.up("md")]: {
			marginRight: 445
		}
	}
}));

export default styles;
