import React, { cloneElement, useCallback } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import ConditionalWrapper from "@micado-digital/react-ui/components/ConditionalWrapper";
import Document from "@micado-digital/react-shop/components/DigitalProductDetails/Document";
import Image from "@micado-digital/react-shop/components/DigitalProductDetails/Image";
import Video from "../Video";

import styles from "./Content.styles";

const Accordion = loadable(() => import("@micado-digital/react-shop/components/Accordion"));

const Content = ({ isPlaying, items, lang, productID, setIsPlaying, setVideo, video }) => {
	const css = styles();

	const getMediaTypeComponent = useCallback(
		mediaType => {
			const components = {
				image: <Image />,
				document: <Document />,
				video: (
					<Video
						isPlaying={isPlaying}
						lang={lang}
						productID={productID}
						video={video}
						setIsPlaying={setIsPlaying}
						setVideo={setVideo}
					/>
				)
			};

			return components[mediaType];
		},
		[isPlaying, lang, productID, setIsPlaying, setVideo, video]
	);

	if (isEmpty(items)) return <></>;

	return (
		<div className={clsx(css.content, "mco-shop-myaccount-digital-product-details-content")}>
			{items?.map(item => {
				const { id: itemID, media: { mediaType, title } = {} } = item;

				return (
					<ConditionalWrapper
						condition={mediaType !== "video"}
						key={itemID}
						wrapper={children => (
							<Accordion defaultExpanded={false} title={title}>
								{children}
							</Accordion>
						)}
					>
						{cloneElement(getMediaTypeComponent(mediaType), { ...item })}
					</ConditionalWrapper>
				);
			})}
		</div>
	);
};

export default Content;
