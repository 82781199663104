import React, { useMemo } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import ToggleBookmark from "@micado-digital/react-bookmark/ReactBookmark/toggleBookmark";
import FallbackImage from "@micado-digital/react-ui/components/FallbackImage";
import calculatePercentage from "@micado-digital/react-shop/utils/calculatePercentage";
import Price from "@micado-digital/react-shop/components/Price";
import Tags from "@micado-digital/react-shop/components/Tags";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@micado-digital/react-ui/components/Local";
import localDE from "@micado-digital/react-shop/locals/de.json";
import localEN from "@micado-digital/react-shop/locals/en.json";
import localIT from "@micado-digital/react-shop/locals/it.json";

import styles from "./ProductTeaser.styles";

const ProductTeaser = ({ lang, product }) => {
	const {
		awardTag,
		awardTitle,
		combinationID = 0,
		mediaList,
		pageName,
		price,
		title
	} = product || {};
	const { REACT_APP_PATH } = process.env;
	const { amount, originalAmount, priceOption, scaleUnitAmount, scaleUnitTitle } = price || {};
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN,
			it: localIT
		}
	});

	const tags = useMemo(() => {
		let _tags = [];

		if (amount !== originalAmount) {
			_tags.push({
				color: "primary",
				tag: "discount",
				title: `${calculatePercentage(amount, originalAmount)}%`
			});
		}

		if (awardTag && awardTitle) {
			_tags.push({
				color: "default",
				tag: awardTag,
				title: awardTitle
			});
		}

		return _tags;
	}, [amount, awardTag, awardTitle, originalAmount]);

	const link = useMemo(() => {
		return `${pageName}${combinationID ? `?combinationID=${combinationID}` : ``}`;
	}, [combinationID, pageName]);

	return (
		<div className={clsx(css.productTeaser, "mco-shop-product-teaser")}>
			<div className={clsx(css.image, "mco-shop-product-teaser__image-wrapper")}>
				{!isEmpty(mediaList) ? (
					<>
						<div className={css.originalImage}>
							<ReactHTMLPicture
								media={mediaList?.[0]}
								mediaFormats={{
									xs: "product-teaser"
								}}
							/>
						</div>
						<div className={css.backgroundImage}>
							<ReactHTMLPicture
								media={mediaList?.[0]}
								mediaFormats={{
									xs: "product-teaser"
								}}
							/>
						</div>
					</>
				) : (
					<div className={css.fallbackImage}>
						<FallbackImage />
					</div>
				)}
				{!isEmpty(tags) && <Tags classNames={css.tags} tags={tags} />}
			</div>
			<div className={clsx(css.content, "mco-shop-product-teaser__content")}>
				<Typography
					className={clsx(css.title, "mco-shop-product-teaser__title")}
					dangerouslySetInnerHTML={{ __html: title }}
				/>
				<Price
					classNames={css.price}
					amount={amount}
					lang={lang}
					originalAmount={originalAmount}
					priceOption={priceOption}
					scaleUnitAmount={scaleUnitAmount}
					scaleUnitTitle={scaleUnitTitle}
				/>
				<ReactLink className={clsx(css.button, "mco-shop-product-teaser__button")} to={link}>
					<Button color="primary" fullWidth variant="contained">
						<Local identifier="reactShop.productOverview.buttons.details" l={l} />
					</Button>
				</ReactLink>
			</div>

			<div className={clsx(css.bookmark, "mco-shop-product-teaser__bookmark")}>
				<ToggleBookmark
					componentBookmarked={
						<ReactSVGIcon
							color="primary"
							src={`${REACT_APP_PATH}/img/icons/shop/bookmark-selected.svg`}
							size={24}
						/>
					}
					componentBookmarkedHover={
						<ReactSVGIcon
							color="primary"
							src={`${REACT_APP_PATH}/img/icons/shop/bookmark-selected.svg`}
							size={24}
						/>
					}
					componentDefault={
						<ReactSVGIcon
							color="primary"
							src={`${REACT_APP_PATH}/img/icons/shop/bookmark.svg`}
							size={24}
						/>
					}
					componentHover={
						<ReactSVGIcon
							color="primary"
							src={`${REACT_APP_PATH}/img/icons/shop/bookmark-selected.svg`}
							size={24}
						/>
					}
					data={{ ...product }}
					validationFields={["id", "objectType"]}
				/>
			</div>
		</div>
	);
};

export default ProductTeaser;
