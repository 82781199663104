import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	productTeaser: {
		background: "#FFFFFF",
		color: theme.palette?.text?.secondary,
		display: "flex",
		flexDirection: "column",
		position: "relative"
	},
	image: {
		display: "flex",
		justifyContent: "center",
		maxHeight: 240,
		position: "relative"
	},
	originalImage: {
		position: "relative",
		zIndex: 2,
		"& img": {
			height: "auto !important",
			maxHeight: "100% !important",
			maxWidth: "100% !important",
			objectFit: "none !important",
			width: "auto !important"
		}
	},
	backgroundImage: {
		bottom: 0,
		left: 0,
		opacity: 0.5,
		overflow: "hidden",
		position: "absolute",
		right: 0,
		top: 0,
		zIndex: 1,
		"& img": {
			filter: "blur(15px)"
		}
	},
	fallbackImage: {
		display: "flex",
		minHeight: "100%",
		paddingBottom: "58.53658536585366%",
		position: "relative",
		width: "100%",
		"& .mco-fallback-image": {
			background: "none",
			left: 0,
			position: "absolute",
			top: 0,
			"& img": {
				width: "100% !important"
			}
		}
	},
	content: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		padding: theme.spacing(3)
	},
	title: {
		color: "#14382B",
		fontSize: 22,
		lineHeight: 1.4
	},
	price: {
		marginTop: "auto",
		paddingTop: theme.spacing(2)
	},
	button: {
		display: "block",
		marginTop: theme.spacing(2),
		position: "relative",
		width: "100%",
		zIndex: 5
	},
	bookmark: {
		right: theme.spacing(2),
		position: "absolute",
		top: theme.spacing(2),
		zIndex: 5
	},
	tags: {
		bottom: 0,
		left: 0,
		position: "absolute",
		zIndex: 3
	}
}));

export default styles;
