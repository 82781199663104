import React, { useMemo } from "react";
import clsx from "clsx";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import Typography from "@material-ui/core/Typography";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@micado-digital/react-ui/components/Local";
import localDE from "../../../../locals/de.json";
import localEN from "../../../../locals/en.json";

import styles from "./OrderDate.styles";

const OrderDate = ({ date, lang }) => {
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const formattedDate = useMemo(() => {
		return format(new Date(date), "P", {
			locale: lang === "de" ? deLocale : enLocale
		});
	}, [date, lang]);

	return (
		<div
			className={clsx(css.orderDate, "mco-shop-myaccount-digital-product-details-order-date")}
		>
			<Typography className={css.label}>
				<Local identifier="reactShop.myAccount.digitalProducts.labels.orderDate" l={l} />
			</Typography>
			<Typography>{formattedDate}</Typography>
		</div>
	);
};

export default OrderDate;
