import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	valid: {
		alignItems: "center",
		display: "flex",
		justifyContent: "space-between"
	},
	label: {
		fontSize: "1.25rem",
		fontWeight: theme.typography.fontWeightBold
	}
}));

export default styles;
