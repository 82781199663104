import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	image: {
		background: theme.palette.common.white,
		marginBottom: theme.spacing(4),
		maxHeight: 480,
		"& img": {
			height: "100% !important",
			margin: "0 auto",
			maxWidth: "100%",
			width: "auto !important"
		}
	}
}));

export default styles;
