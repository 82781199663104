import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	item: {
		background: "#FFFFFF",
		color: theme.palette?.text?.secondary,
		display: "flex",
		flexDirection: "column",
		position: "relative",
		"& .mco-shop-myaccount__progress": {
			paddingTop: theme.spacing(2)
		}
	},
	disabled: {
		filter: "grayscale(1)"
	},
	image: {
		maxHeight: 240,
		position: "relative",
		"& img": {
			height: "100% !important",
			margin: "0 auto",
			maxWidth: "100%",
			width: "auto !important"
		}
	},
	fallbackImage: {
		display: "flex",
		minHeight: "100%",
		paddingBottom: "58.53658536585366%",
		position: "relative",
		width: "100%",
		"& .mco-fallback-image": {
			background: "none",
			left: 0,
			position: "absolute",
			top: 0,
			"& img": {
				width: "100% !important"
			}
		}
	},
	content: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		padding: theme.spacing(3)
	},
	title: {
		color: "#14382B",
		fontSize: 22,
		lineHeight: 1.4
	}
}));

export default styles;
