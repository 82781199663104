import React from "react";
import clsx from "clsx";
import UIContainer from "@material-ui/core/Container";

import styles from "./Container.styles";

const Container = ({ className, children }) => {
	const css = styles();

	return <UIContainer className={clsx(className, css.container)}>{children}</UIContainer>;
};

export default Container;
