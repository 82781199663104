import React from "react";
import clsx from "clsx";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@micado-digital/react-ui/components/Local";
import localDE from "../../../locals/de.json";
import localEN from "../../../locals/en.json";

import styles from "./Progress.styles";

const Progress = ({ lang, value = 0 }) => {
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	return (
		<div className={clsx(css.progress, "mco-shop-myaccount__progress")}>
			<LinearProgress
				className={clsx(css.line, "mco-shop-myaccount__progress-line")}
				value={value}
				variant="determinate"
			/>
			<div className={clsx(css.wrapper, "mco-shop-myaccount__progress-wrapper")}>
				<Typography className={clsx(css.value, "mco-shop-myaccount__progress-value")}>
					{`${value}%`}
				</Typography>
				<Typography className={clsx(css.label, "mco-shop-myaccount__progress-label")}>
					<Local identifier="reactShop.myAccount.digitalProducts.labels.passed" l={l} />
				</Typography>
			</div>
		</div>
	);
};

export default Progress;
