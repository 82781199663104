import axios from "axios";

const getVideo = async url => {
	if (!url) return;

	const { REACT_APP_PATH } = process.env;
	const FETCH_URL = `${REACT_APP_PATH}${url}`;

	const headers = {};

	if (localStorage.getItem("logintoken")) {
		headers["Authorization"] = "Bearer " + localStorage.getItem("logintoken");
	}

	try {
		const response = await axios.post(
			FETCH_URL,
			{},
			{
				headers
			}
		);

		const data = await response.data;

		return data ?? {};
	} catch (error) {
		return error;
	}
};

export default getVideo;
