import React, { useContext, useEffect, useState } from "react";
import loadable from "@loadable/component";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PageContext from "@micado-digital/react-ui/context/PageContext";
import CartButton from "@micado-digital/react-shop/views/ProductDetail01/CartButton";
import CombinationSelection from "@micado-digital/react-shop/views/ProductDetail01/CombinationSelection";
import Description from "@micado-digital/react-shop/views/ProductDetail01/Description";
import Gallery from "@micado-digital/react-shop/views/ProductDetail01/Gallery";
import Price from "@micado-digital/react-shop/views/ProductDetail01/Price";
import ProductSelection from "@micado-digital/react-shop/views/ProductDetail01/ProductSelection";
import Quantity from "@micado-digital/react-shop/views/ProductDetail01/Quantity";
import Title from "@micado-digital/react-shop/views/ProductDetail01/Title";
import Container from "../../components/Container";

import styles from "./ProductDetail.styles";

const Detail = loadable(() => import("@micado-digital/react-shop/ProductDetail"));

const ProductDetail = ({ defaultLang = "de" }) => {
	const [pageData] = useContext(PageContext);
	const { lang: pageDataLang, setupDetails: data } = pageData || {};
	const [lang, setLang] = useState(defaultLang);
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const css = styles();

	useEffect(() => {
		if (!pageDataLang) return;

		setLang(pageDataLang);
	}, [pageDataLang]);

	return (
		<Detail
			data={data ? JSON.parse(data) : null}
			lang={lang}
			pagename={window.location.pathname}
		>
			<Container className={clsx(css.productDetail, "mco-shop-product-detail")}>
				<div className={css.title}>
					<Title />
				</div>
				{!isDesktop && (
					<div className={css.gallery}>
						<Gallery mediaFormats={{ xs: "product-detail" }} thumbsMediaFormat="" />
					</div>
				)}
				<div className={css.wrapper}>
					<div className={css.rightColumn}>
						<div className={css.price}>
							<Price lang={lang} />
						</div>
						<ProductSelection lang={lang}>
							<div className={css.combinationSelection}>
								<CombinationSelection lang={lang} />
							</div>
							<div className={css.buttons}>
								<Quantity />
								<CartButton />
							</div>
						</ProductSelection>
					</div>
					<div className={css.content}>
						{isDesktop && (
							<Gallery mediaFormats={{ xs: "product-detail" }} thumbsMediaFormat="" />
						)}
						<div className={css.description}>
							<Description />
						</div>
					</div>
				</div>
			</Container>
		</Detail>
	);
};

export default ProductDetail;
