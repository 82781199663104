import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	productOverview: {
		"& .mco-shop-product-overview__filter": {
			"& .mco-filter": {
				[theme.breakpoints.up("md")]: {
					display: "grid",
					gap: theme.spacing(4.375),
					gridTemplateColumns: "1fr 1fr 1fr",
					rowGap: theme.spacing(7),
					width: "100%"
				}
			},
			"& .mco-filter-item": {
				"&__button": {
					background: "#FFFFFF",
					borderColor: "#E1DCBE",
					borderRadius: 0,
					justifyContent: "space-between",
					minHeight: 56,
					width: "100%",
					"&--selected": {
						"& .mco-filter-item__icon": {
							"& svg": {
								width: "24px !important",
								"& path": {
									fill: theme.palette?.getContrastText(theme.palette?.secondary?.main)
								}
							}
						}
					}
				},
				"&__label": {
					fontSize: 18,
					fontWeight: 500,
					textTransform: "none"
				},
				"&__counter": {
					marginLeft: "auto"
				},
				"&__icon": {
					"& svg": {
						width: "24px !important",
						"& path": {
							fill: theme.palette?.secondary?.main
						}
					}
				}
			}
		},
		"& .mco-shop-product-overview-selected-filter": {
			"&__value-button": {
				background: "#FFFFFF",
				borderColor: "#E1DCBE"
			}
		},
		"& .mco-shop-product-overview-list": {
			marginLeft: 0,
			marginRight: 0,
			gap: theme.spacing(2.5),
			gridTemplateColumns: "1fr",
			[theme.breakpoints.up("sm")]: {
				gridTemplateColumns: "1fr 1fr"
			},
			[theme.breakpoints.up("md")]: {
				gap: theme.spacing(4.375),
				gridTemplateColumns: "1fr 1fr 1fr",
				rowGap: theme.spacing(7)
			}
		},
		"& .mco-shop-product-overview-pagination": {
			[theme.breakpoints.up("md")]: {
				marginTop: 45
			}
		}
	},
	paper: {
		height: "100%",
		maxWidth: "100%",
		overflowX: "hidden",
		width: "100%",
		"& .mco-shop-product-overview__filter": {
			display: "flex",
			flexDirection: "column",
			height: "100%",
			padding: theme.spacing(5, 2, 0)
		}
	}
}));

export default styles;
